Chart.register(ChartDeferred);

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector(".js-custom-tooltip");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.classList.add("js-custom-tooltip", "tooltip");
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const customTooltip = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const text = tooltip.title[0];
    const value = tooltip.body[0].lines[0];
    const color = tooltip.labelColors[0].backgroundColor;

    const contentEl = document.createElement("div");
    contentEl.classList.add("tooltip__content");
    contentEl.innerHTML = `
      <div class='tooltip__left'>
        <div class='tooltip__circle' style='background: ${color};'></div>
        <p class='tooltip__text'>${text}</p>
      </div>
      <div class='tooltip__right'>
        <span class='tooltip__value'>${value}%</span>
      </div>
    `;

    // Empty tooltipEl each time
    tooltipEl.innerHTML = "";
    tooltipEl.append(contentEl);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
};

export default function () {
  let values; // Declare values in the broader scope
  const dataRows = Array.from(document.querySelectorAll(".js-data-row"));

  const titles = dataRows.map(function (element) {
    const titleEl = element.querySelector(".js-title");
    const title = titleEl.innerHTML;
    return title;
  });

  const colors = dataRows.map(function (element) {
    const colorEl = element.querySelector(".js-color");
    const color =
      getComputedStyle(colorEl).getPropertyValue("background-color");
    return color;
  });

  const ctx = document.getElementById("js-chart").getContext("2d");
  const myChart = new Chart(ctx, {
    type: "doughnut",
    data: {
      labels: titles,
      datasets: [
        {
          data: values,
          backgroundColor: colors,
          hoverOffset: 20,
          hoverBackgroundColor: colors,
          hoverBorderWidth: 0,
          borderColor: "rgba(255, 235, 230, 1)",
        },
      ],
    },
    plugins: [ChartDeferred],
    options: {
      layout: {
        padding: 10,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: "nearest",
          external: customTooltip,
        },
      },
    },
  });

  // Function to initialize values with month data
  function setDefaultMonthValues() {
    values = dataRows.map(function (element) {
      const valueEl = element.querySelector(".js-data-month");
      const value = parseFloat(valueEl.innerHTML);
      return value;
    });

    // Update the chart data with the default 'values' for the month
    if (myChart) {
      myChart.data.datasets[0].data = values;
      myChart.update();
    }
  }

  // Call the function to set default values when the page loads
  document.addEventListener("DOMContentLoaded", setDefaultMonthValues);

  const radioBtns = document.querySelectorAll('input[type="radio"]');
  const monthData = document.getElementById("monthData");
  const yearData = document.getElementById("yearData");

  radioBtns.forEach((radioBtn) => {
    radioBtn.addEventListener("change", (e) => {
      if (e.target.value === "month") {
        // Hide the yearData when month is selected
        yearData.setAttribute("aria-hidden", "true");
        monthData.removeAttribute("aria-hidden");
        // Update the chart data with the new 'values' for the month
        values = dataRows.map(function (element) {
          const valueEl = element.querySelector(".js-data-month");
          const value = parseFloat(valueEl.innerHTML);
          return value;
        });

        // Update the chart data with the new 'values' for the month
        if (myChart) {
          myChart.data.datasets[0].data = values;
          myChart.update();
        }
      } else if (e.target.value === "year") {
        // Hide the monthData when year is selected
        monthData.setAttribute("aria-hidden", "true");
        yearData.removeAttribute("aria-hidden");
      // Update the chart data with the new 'values' for the year
        values = dataRows.map(function (element) {
          const valueEl = element.querySelector(".js-data-year");
          const value = parseFloat(valueEl.innerHTML);
          return value;
        });

        // Update the chart data with the new 'values' for the year
        if (myChart) {
          myChart.data.datasets[0].data = values;
          myChart.update();
        }
      }
    });
  });
}

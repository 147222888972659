let prevScroll = window.scrollY;

function initScrollListen() {
  let scrollPos = 0;
  let ticking = false;

  window.addEventListener('scroll', (e) => {
    scrollPos = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        checkScroll(scrollPos);
        ticking = false;
      });

      ticking = true;
    }
  })
}

function checkScroll(scrollPos) {
  const body = document.body;
  const homeHero = document.querySelector('#homeHero');

  if (homeHero) {
    const heroHeight = homeHero.getBoundingClientRect().height;

    if (scrollPos > heroHeight && scrollPos < prevScroll) {
      body.classList.add('page-is-scrolled');
    } else {
      body.classList.remove('page-is-scrolled');
    }
  } else {
    if (scrollPos > 0 && scrollPos < prevScroll) {
      body.classList.add('page-is-scrolled');
    } else {
      body.classList.remove('page-is-scrolled');
    }
  }

  prevScroll = scrollPos;
}

export default function() {
  initScrollListen();
}

import page from './modules/page';
import header from './modules/header';
import nav from './modules/nav';
import myChart from './modules/chart';
import dropdown from './modules/dropdown';

page();
header();
nav.init();
myChart();
dropdown.init();


import gsap from 'gsap';
import axios from 'axios';
import Player from '@vimeo/player';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const vimeoURL = 'https://vimeo.com/api/oembed.json';

// Disable this in dev, seems to cause issues with Browsersync??
window.onYouTubeIframeAPIReady = function() {
  const youTubeVideos = Array.from(document.querySelectorAll('.js-video')).filter(video => video.dataset.videoType === 'youtube');
  youTubeVideos.forEach(video => initYouTubeVideo(video));
}

function initYouTubeVideo(video) {
  const container = video.querySelector('.js-video-container');
  const link = video.querySelector('.js-video-link');
  const urlObject = new URL(link.href);
  let videoId;
  if (urlObject.search.length) {
    const params = urlObject.searchParams;
    videoId = params.get('v');
  } else if (urlObject.pathname.length) {
    videoId = urlObject.pathname.split('/')[1];
  }
  if (!videoId) {
    return;
  }

  const player = new YT.Player(container, {
    videoId: videoId,
    enablejsapi: 1,
    origin: window.location.origin,
    events: {
      'onReady': () => {
        video.classList.add('video-is-loaded');
        link.addEventListener('click', play);
      },
    }
  });

  // Create function inside here so it's correctly scoped
  function play(e) {
    e.preventDefault();
    player.playVideo();
    video.classList.add('video-is-clicked');
  }
}

async function initVimeoVideo(video) {
  const container = video.querySelector('.js-video-container');
  const link = video.querySelector('.js-video-link');
  const videoURL = encodeURI(link.href);
  const thumb = video.querySelector('.js-video-thumb');

  try {
    // Get hi-res thumbnail from Vimeo
    const res = await axios({
      url: vimeoURL,
      params: {
        width: 1280,
        url: videoURL
      }
    });

    const { width, height } = res.data;
    const aspectRatio = height / width;

    const options = {
      url: videoURL,
      title: false,
      portrait: false,
      byline: false,
      transparent: false,
      dnt: true,
      loop: video.dataset.loop,
      muted: video.dataset.loop
    }

    if (width === height) {
      video.classList.add('video--square');
    } else {
      options.width = 1280;
      options.height = 720;
    }

    thumb.src = res.data.thumbnail_url;
    video.classList.add('video-is-loaded');
    const player = new Player(container, options);

    // Create function inside here so it's correctly scoped
    function play(e) {
      if (e) e.preventDefault();
      player.play()
      .then(function() {
        video.classList.add('video-is-clicked');
      })
      .catch(function(error) {
        console.log(error);
      });
    }

    link.addEventListener('click', play);

    if (video.dataset.autoplay) {
      player.ready().then(function() {
        ScrollTrigger.create({
          trigger: video,
          start: 'top center',
          once: true,
          onEnter() {
            play();
          }
        });
      });  
    }
  } catch {
    video.classList.add('video-not-loaded');
  }
}

export default function() {
  const vimeoVideos = Array.from(document.querySelectorAll('.js-video')).filter(video => video.dataset.videoType === 'vimeo');
  if (vimeoVideos.length) {
    vimeoVideos.forEach(video => initVimeoVideo(video));
  }
  // Disable this in dev, seems to cause issues with Browsersync??
  // Check for existing youtube script tag
  const existingTag = document.querySelector('script[src="https://www.youtube.com/iframe_api"]');
  if (existingTag) {
    window.onYouTubeIframeAPIReady();
    return;
  }

  const tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  document.body.prepend(tag);
}

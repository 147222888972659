import barba from '@barba/core';
import gsap from 'gsap';
import nav from './nav';
import load from './load';

const body = document.body;

function leave(data) {
  nav.hide();
  const { container } = data.current;
  return gsap.to(container, {
    opacity: 0,
    duration: 0.1,
    ease: 'none',
  });
}

function beforeLeave(data) {
  body.classList.add('page-is-changing');
}

function beforeEnter(data) {
  // Check if initial page load, don't want to load scripts twice
  const { container: currentContainer } = data.current;
  const { container, html } = data.next;
  const parser = new DOMParser();
  const resHTML = parser.parseFromString(data.next.html, "text/html");
  const ajaxContent = resHTML.querySelector('.js-ajax-content');

  const activePageClass = Array.from(body.classList).find(item => item.includes('-page-is-active'));
  const newPageClass = ajaxContent.dataset.pageClass;
  if (activePageClass !== newPageClass) {
    body.classList.remove(activePageClass);
    body.classList.add(newPageClass);
  }

  const scriptContainer = resHTML.querySelector('.js-module-script');
  // Re-run any initialising scripts that depend on elements being present on the page (as opposed to click events):
  // Check if appended content contains any script tags
  if (scriptContainer && currentContainer) {
    const scripts = Array.from(scriptContainer.querySelectorAll('script'));
    scripts.forEach(script => {
      const newScript = document.createElement('script');
      newScript.type = 'text/javascript';
      newScript.src = script.src;
      container.append(newScript);
    });
  }
}

export default function() {
  body.classList.remove('no-js');

  document.addEventListener('DOMContentLoaded', function(e) {
    body.classList.remove('page-is-loading');
  });

  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  barba.init({
    timeout: 10000,
    requestError: (trigger, action, url, response) => {
      console.log(trigger);
      console.log(action);
      console.log(url);
      console.log(response);
      // Barba will redirect to the requested url if a prefetch timeout occurs
    },
    transitions: [
      {
        name: 'self',
        beforeLeave: beforeLeave,
        beforeEnter: beforeEnter,
        leave: leave,
        after: load,
      },
      {
        name: 'page-transition',
        beforeLeave: beforeLeave,
        beforeEnter: beforeEnter,
        leave: leave,
        after: load,
        once: load,
      },
    ],
  });
}

import gsap from 'gsap';

function showKey(keyContainer) {
  keyContainer.classList.add('key-is-expanded');
  const tl = gsap.timeline();
  tl.to(keyContainer, {
    height: 'auto',	
  })
}

function hideKey(keyContainer) {
  keyContainer.classList.remove('key-is-expanded');
  gsap.to(keyContainer, {
    height: 0,
    clearProps: true,
  });
}

function toggle() {
  const keyContainer = document.querySelector('.js-key');
  const arrow = document.querySelector('.js-arrow');
  if (keyContainer.classList.contains('key-is-expanded')) {
    hideKey(keyContainer);
    arrow.classList.remove('key-is-expanded');
  } else {
    showKey(keyContainer);
    arrow.classList.add('key-is-expanded');
  }
}

function init() {
  const btn = document.querySelector('.js-key-header');
  btn.addEventListener('click', toggle);
}

const dropdown = {
  init: init,
}

export default dropdown;
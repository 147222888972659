import video from './video';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function(data) {
  document.body.classList.remove('page-is-changing');
  // Initialise any videos on the page
  video();
  // Set up scroll triggers
  const items = Array.from(document.querySelectorAll('.js-animate-in, .js-animate-in-children > *'));
  if (items.length) {
    ScrollTrigger.refresh();
    ScrollTrigger.batch(items, {
      onEnter: (els) => {
        gsap.to(els, {
          y: 0,
          opacity: 1,
          duration: 0.3,
          stagger: 0.1,
          ease: 'back.out(1.1)'
        });
      },
      once: true,
    });
  }
  const { current, next, trigger } = data;
  if (trigger === 'back' || trigger === 'forward' || trigger === 'popstate') {
    if (current.namespace === 'work' && next.namespace === 'work') {
      // Keep scroll position if navigating between filters
      return;
    }
  }
  window.scrollTo(0, 0);
}

import page from './page';
import gsap from 'gsap';

const body = document.body;
const navEl = document.querySelector('#nav');
const navItems = Array.from(document.querySelectorAll('.js-nav-item'));
const navLinks = Array.from(document.querySelectorAll('.js-nav-link'));
const activeClass = 'nav-is-active';

const tl = gsap.timeline({paused: true});
tl
.fromTo(navEl, {
  display: 'none',
  opacity: 0
}, {
  display: 'flex',
  opacity: 1,
  duration: 0.1,
  immediateRender: false
})
.fromTo(navItems, {
  x: '1%',
  opacity: 0
}, {
  x: 0,
  opacity: 1,
  duration: 0.25,
  stagger: 0.05
}, "-=0.1");

function showNav() {
  body.classList.add(activeClass);
  tl.play();
}

function hideNav() {
  if (body.classList.contains(activeClass)) {
    body.classList.remove(activeClass);
    tl.timeScale(2).reverse(0);
  }
}

function toggle() {
  if (body.classList.contains(activeClass)) {
    hideNav();
  } else {
    showNav();
  }
}

function handleNavLink(e) {
  e.preventDefault();
  page.handleLink(e.currentTarget.href);
  hideNav();
}

function init() {
  const btn = document.querySelector('#nav-btn');
  btn.addEventListener('click', toggle);
}

const nav = {
  init: init,
  hide: hideNav
}

export default nav;
